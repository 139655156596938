var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "PageNotFound" },
    [
      _c("h1", [_vm._v("404 - Page Not Found")]),
      _vm.reason
        ? _c("h2", [_vm._v("\n    " + _vm._s(_vm.reason) + "\n  ")])
        : _vm._e(),
      _c(
        "base-button",
        { staticClass: "bg-blue fc-white", on: { click: _vm.goBack } },
        [_vm._v("\n    Go Back\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }