<template>
  <div class="PageNotFound">
    <h1>404 - Page Not Found</h1>
    <h2 v-if="reason">
      {{ reason }}
    </h2>
    <base-button
      @click="goBack"
      class="bg-blue fc-white"
    >
      Go Back
    </base-button>
  </div>
</template>

<script>
// Components
import BaseButton from '../components/base-button.vue'

export default {
  name: 'PageNotFound',

  components: {
    BaseButton,
  },

  props: {
    reason: {
      type: String,
      required: false,
      default: null,
    },
  },

  methods: {
    goBack () {
      this.$router.go(-2)
    }
  },
}
</script>

<style lang="sass">
.PageNotFound
</style>